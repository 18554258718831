.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover{
    background-color: #ED7122 !important;
    border-radius: 5px;
}
.rdtPicker td.rdtToday:before {
    border-bottom: 10px solid #ED7122 !important;
    border-left: 10px solid transparent !important;
}
.rdtPicker td:hover{
    border-radius: 5px;
    background-color: rgba(0, 0, 255, 0.04) !important;
}