.resumerCout {
    font-size: 25px;
    background-color: #e3e3ee;
    border-left: 4px solid #ED7122;
    display: inline-block;
  }
  
  .spanSomme {
    font-size: 25px;
  }
  
  .style__planning {
    color: #ED7122;
    border-bottom: 2px solid lightgray;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  /* les styles appliqués aux input de Budgétisation */
  
  .lineChartsBudgetisationSpanLeft {
    border-bottom: 1px solid transparent;
    width: 250px;
    height: auto;
    display: flex;
    justify-content: left;
    padding-left: 10px;
    position: absolute;
  }
  .lineContainer{
    position: relative;
    width: auto;
  }
  
  .lineChartsBudgetisationSpanrigth {
    float: right;
  }
  
  .budgetSelectInput {
    width: 80px;
    height: 35px;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid rgb(128, 127, 127);
    opacity: 1;
    font-size: 15px;
  }
  
  .AnneeSelectInput {
    width: 60px;
    height: 35px;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid rgb(128, 127, 127);
    opacity: 1;
    font-size: 15px;
    margin: 5px;
  }
  
  .buttonValiderBudgetisation {
    color: white;
    width: 60px;
    height: 35px;
    background: #ED7122 0% 0% no-repeat padding-box;
    opacity: 1;
    font-size: 15px;
    border: none;
  }
  
  .btnSemaineEtTache {
    width: 65px;
    height: 35px;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid rgb(128, 127, 127);
    opacity: 1;
    font-size: 15px;
    margin: 5px;
  }
  .titlePlannig {
    float: left;
    color: #ED7122;
  }
  
  .btnSemaineEtTacheRight {
    float: right;
    margin: 15px;
  }
  
  .position_ExerciceAnnee {
    position: absolute;
    top: 7%;
    left: 4%;
    border: none;
  }
  
  .line_chart_default_btn {
    padding: 6px 5px;
    font-size: 1.4rem;
    cursor: pointer;
  }
  .display_line_charts_btn {
    font-weight: bold;
    border-bottom: 3px solid #ED7122;
    padding: 6px 5px;
    font-size: 1.4rem;
    cursor: pointer;
  }
  .defaultButton {
    font-size: 1.4rem;
  }
  .activeButton {
    font-weight: bold;
    font-size: 1.4rem;
    background-color: #ED7122;
    color: #fff;
  }
  