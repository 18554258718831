.tail-datetime-calendar {
    width: 100%;
    border-radius: 3px;
    height: 100%;
  }

  .tail-datetime-calendar:after {
    clear: both;
    content: "";
    display: block;
    font-size: 0;
    visibility: hidden;
  }

  .tail-datetime-calendar.calendar-static {
    margin-left: auto;
    margin-right: auto;
  }

  .tail-datetime-calendar .calendar-navi {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    background-color: #ED7122;
    border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
  }

  .tail-datetime-calendar .calendar-navi span {
    cursor: pointer;
    color: #fff;
    margin: 0;
    padding: 0;
    display: table-cell;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.15);
    background-repeat: no-repeat;
    background-position: center center;
  }

  .tail-datetime-calendar .calendar-navi span:first-child,
  .tail-datetime-calendar .calendar-navi span:last-child {
    width: 35px;
    padding: 0 0 5px 0;
    font-size: 22px;
  }

  .tail-datetime-calendar .calendar-navi span:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }

  .tail-datetime-calendar .calendar-date {
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
  }

  .tail-datetime-calendar .calendar-date table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-spacing: 0;
    border-collapse: collapse;
  }

  .tail-datetime-calendar .calendar-date table thead tr>*,
  .tail-datetime-calendar .calendar-date table tbody tr>* {
    color: black;
    width: 30px;
    height: 30px;
    padding: 0;
    font-size: 12px;
    text-align: center;
    font-weight: normal;
    line-height: 10px;
    border: 0;
    background-color: #FFFFFF;
  }

  .tail-datetime-calendar .calendar-date table tbody tr>* {
    color: #334455;
    cursor: pointer;
    position: relative;
    font-size: 14px;
    background-color: #ffffff;
  }

  .tail-datetime-calendar .calendar-date table tbody tr>*>span {
    color: inherit;
    z-index: 10;
    position: relative;
  }

  .tail-datetime-calendar .calendar-date table tbody tr>*::after {
    top: 0;
    left: 9%;
    right: 9%;
    width: 29px;
    height: 29px;
    margin: auto;
    content: "";
    display: block;
    position: absolute;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    -webkit-border-radius: 50%;
  }

  .tail-datetime-calendar .calendar-date table tbody tr>*.today {
    color: #3296c8;
  }

  .tail-datetime-calendar .calendar-date table tbody tr>*.today:after {
    border-color: #3296c8;
  }

  .tail-datetime-calendar .calendar-date table tbody tr>*:hover {
    color: black;
  }

  .tail-datetime-calendar .calendar-date table tbody tr>*:hover:after {
    opacity: 1;
    background-color: lightgrey;
  }

  .tail-datetime-calendar .calendar-date table tbody tr>*.empty,
  .tail-datetime-calendar .calendar-date table tbody tr>*.disable {
    color: #8899aa;
    cursor: not-allowed;
    background-color: #efefef;
  }

  .tail-datetime-calendar .calendar-date table tbody tr>*.empty:hover:after,
  .tail-datetime-calendar .calendar-date table tbody tr>*.disable:hover:after {
    content: "";
    display: none;
  }

  .tail-datetime-calendar .calendar-date table tbody tr>*.current span {
    color: #fff;
  }

  .tail-datetime-calendar .calendar-date table tbody tr>*.current:after {
    border-color: #3296c8;
    background-color: #3296c8;
  }

  .tail-datetime-calendar .calendar-date table.calendar-month tbody tr>* {
    width: 81.66666666666667px;
    padding: 5px;
    line-height: 25px;
  }

  .tail-datetime-calendar .calendar-date table.calendar-month tbody tr>* span {
    display: block;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 3px;
    -webkit-border-radius: 3px;
  }

  .tail-datetime-calendar .calendar-date table.calendar-month tbody tr>*:hover span {
    border-color: #d0d0d0;
    box-shadow: 0 1px 0 0 #efefef;
    -webkit-box-shadow: 0 1px 0 0 #efefef;
  }

  .tail-datetime-calendar .calendar-time {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
    text-align: center;
    background-color: #ffffff;
    border-radius: 0 0 3px 3px;
    -webkit-border-radius: 0 0 3px 3px;
  }

  .tail-datetime-calendar .calendar-date+.calendar-time {
    border-top: 1px solid #d0d0d0;
    background-color: #f8f8f8;
  }

  .tail-datetime-calendar .calendar-time .calendar-field {
    width: 33.3333333333%;
    padding: 10px 0;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: top;
  }

  .tail-datetime-calendar .calendar-time .calendar-field:first-child {
    text-align: right;
  }

  .tail-datetime-calendar .calendar-time .calendar-field:first-child:after {
    top: 12px;
    right: -10px;
    content: ":";
    position: absolute;
  }

  .tail-datetime-calendar .calendar-time .calendar-field:last-child {
    text-align: left;
  }

  .tail-datetime-calendar .calendar-time .calendar-field:last-child:after {
    top: 12px;
    left: -10px;
    content: ":";
    position: absolute;
  }

  .tail-datetime-calendar .calendar-time .calendar-field input[type="number"] {
    width: 100%;
    max-width: 50px;
    margin: 0;
    padding: 3px 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: #d0d0d0;
    background-color: #ffffff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
  }

  .tail-datetime-calendar .calendar-time .calendar-field input[type="number"]:hover {
    border-color: #a0a0a0;
  }

  .tail-datetime-calendar .calendar-time .calendar-field input[type="number"]:focus {
    border-color: #3296c8;
  }

  .tail-datetime-calendar .calendar-time .calendar-field label {
    color: #778899;
    margin: 5px 0 0 0;
    padding: 0;
    display: block;
    font-size: 12px;
    line-height: 12px;
  }

  .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_date{
    font-weight: normal !important;
  }
  .dhx_cal_tab{
    color: #ED7122 !important;
    border: 1px solid #ED7122 !important;
    font-weight: normal;
  }
  .dhx_cal_tab.active{
    color: #fff !important;
    border: 1px solid #ED7122 !important;
    background-color: #ED7122 !important;
  }
  .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab{
    box-shadow: -1px 0 0 #ED7122 !important;
  }
  .dhx_cal_today_button{
    font-size: 13px !important;
    font-weight: normal !important;
    color: #ED7122 !important;
  }
  .dhx_cal_prev_button , .dhx_cal_next_button{
    color: #ED7122 !important;
  }
  .dhx_cal_event.dhx_cal_event_drag .dhx_event_resize.dhx_footer, .dhx_cal_event.dhx_cal_event_selected .dhx_event_resize.dhx_footer, .dhx_cal_event:hover .dhx_event_resize.dhx_footer{
    margin-top: 0 !important;
  }
  .dhx_cal_event .dhx_footer, .dhx_cal_event .dhx_select_menu_footer{
    height: auto !important;
  }

  .dhx_cal_ltitle{
    border-bottom: 1px solid #ED7122 !important;
    background: #ED7122 !important;
  }
  .dhx_save_btn_set{
    border: 1px solid #ED7122 !important;
    background-color: #ED7122 !important;
  }
  textarea.dhx_cal_editor{
    color: rgba(0,0,0,.7) !important;
    font-weight: normal !important;
  }
  .dhx_cal_event.dhx_cal_editor{
    background-color: #ED7122 !important;
  }
  .dhx_cal_event.dhx_cal_editor>div{
    width: 134px !important;
    height: auto !important ;
    margin: 0 !important;
    min-height: auto !important;
    overflow: hidden !important;
  }
  .dhx_cal_event_clear{
    color: #ED7122 !important;
  }
  .planningDetail{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    border-top: 1px solid rgba(0,0,0,.1);
    box-sizing: border-box;
    padding: 10px;
  }
  .closeDetilPlanning{
    width: 20px;
    height: 20px;
    background-color: red;
    color: white;
    text-align: center;
    padding-top: 2px;
    box-sizing: border-box;
    cursor: pointer;
  }
