.collaborateurTable {
  height: 100%;
}
.collaborateurList {
  height: auto;
  overflow: scroll;
}
.cadreColorIntervenant {
  width: 12px;
  height: 12px;
  display: block;
}
