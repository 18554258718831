.searchBar{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
}
.searchBar-mod{
    width: 70%;
}
.styleSearchIcon{
    padding: 0 5px;
}
.styleGroupBtn{
    padding: 0 0 0 10px;
    display: flex;
    justify-content: space-around;
}
.styleGroupBtn button{
    background-color: white;
    cursor: pointer;
    color: red;
}
.stylePrint{
    color: #ED7122;
}
.stylePicture{
    color: red;
}
.styleTableTitle{
    font-size: 1.8rem;
    padding: 10px;
}
.styleTableTitleStepper, .styleTableTitleStepper1{
    display: flex;
    height: auto;
    justify-content: space-around;
}
.styleTableTitleStepper button{
    background: transparent;
    color: rgba(0,0,0,0.7);
    font-size: 1.3rem;
    border: none;
    padding: 0;
    cursor: pointer;
    box-sizing: border-box;
}
.styleTableTitleStepper1 button{
    background: transparent;
    color: rgba(0,0,0,0.7);
    font-size: 15px;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    box-sizing: border-box;
    text-align: left;
    width: 10px;
}
.styleTableTitleStepper button:hover, .styleTableTitleStepper1 button:hover{
    background-color: rgba(128, 128, 128, 0.03);
}
.styleTableTitleStepper button:focus{
    color: black;
    background-color: rgba(128, 128, 128, 0.03);
    border-bottom: 2px solid #ED7122;
    box-sizing: border-box;
}
.styleTableTitleStepper1 button:focus{
    color: black;
    background-color: rgba(128, 128, 128, 0.03);
    border-bottom: 3px solid #ED7122;
    box-sizing: border-box;
    text-align: left;
}
.__activated{
    color: black;
    background-color: rgba(128, 128, 128, 0.03);
    border-bottom: 2px solid #ED7122;
    box-sizing: border-box;
    padding: 0 5px;
}

.styleTablePagination{
    padding: 10px;
    right: 0;
}
.stylePaginationGroupe{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    height: auto;
    overflow: hidden;

}
.statusCodeColor{
    display: flex;
    justify-content: left;
    width: 100%;
    height: auto;
}
.colorEtats{
    display: block;
    width: 100px;
    height: auto;
    border-radius: 3px;
    font-size: 12px;
    text-align: center;
    padding: 5px;
}
.colorAttente{
    color: #ED7122;
    background-color: rgba(237, 113, 34, 0.07);
}
.colorAccept{
    color: #3cb878;
    background: #d1f3e0;
}
.colorEnCour{
    color: #feb969;
    background: #fff2d8;
}
.colorRefuse{
    color: #f53c56;
    background: #ffeaea;
}
.colorBrouillons{
    color: gray;
    background: rgba(128, 128, 128, 0.1);
}
.codeColorStyle_Orange,
.codeColorStyle_yellow,
.codeColorStyle_blue,
.codeColorStyle_Green,
.codeColorStyle_Red,
.codeColorStyle_gray{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px 7px;
}
.codeColorStyle_gray{
    background: gray;
}
.codeColorStyle_Orange{
    background: orange;
}
.codeColorStyle_yellow{
    background: orangered;
}
.codeColorStyle_blue{
    background: mediumturquoise;
}
.codeColorStyle_Green{
    background: green;
}
.codeColorStyle_Red{
    background: red;
}
.HeaderStyle{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
}
.__styleImage img{
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}
._styleReturnButton{
    background: #ED7122;
    color: white;
    border: none;
}
._styleReturnButton:hover{
    background: #ED7122;
    color: white;
}

.globalInput,.selectInput_ {
    font-style: italic;
    opacity: 0.7;
    width: 100%;
    font-size: 1.25rem;
    margin-top: 5px;
    color: rgba(0,0,0,0.5);
    outline: none;
  }
  .globalInputExercice {
    font-style: italic;
    opacity: 0.77;
    font-size: 1.25rem;
    padding: 0 2px;
    color: rgba(0,0,0,0.6);
    outline: none;
    height: 30px;
  }
  .cadreLegende{
    display: block;
    width: 12px;
    height: 12px;
    margin: 1px 2px;
  }
  .cadreLegende.orange {
    background-color: #ED7122;
  }
  .cadreLegende.green {
    background-color: green;
  }
  .cadreLegende.black {
    background-color: black;
  }
  .cadreLegende.red {
    background-color: red;
  }
  .cadreLegende.blue {
    background-color: blue;
  }
  .cadreLegende.purple {
    background-color: purple;
  }
  .legendeStyle p{
    font-size: 12px;
  }
  .globalTextarea{
    font-style: italic;
    opacity: 0.7;
    width: 100%;
    font-size: 1.2rem;
    height: 75px;
    margin-top: 5px;
    color: rgba(0,0,0,0.5);
    outline: none;
  }
  .styleTextarea{
    opacity: 0.7;
    font-size: 1.2rem;
    height: 80px;
    color: rgba(0,0,0,0.5);
    outline: none;
  }
  .globalInput:focus, .selectInput_:focus, .globalTextarea:focus, .globalInputExercice:focus{
    outline-style: none;
    outline: none;
  }
.selectInput_, .inputCategorie_{
    border: 1px solid #ED7122;
}

/* les stepper   */
.__steppers{
    width: 100%;
    height: auto;
    padding-top: 5px;
    display: flex;
    justify-content: left;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0,0,0,0.7);  
}
.__stepper{
    font-size: 1.6rem;
    padding: 10px;
    margin-right: 20px;
    color: rgba(0,0,0,0.7);
    cursor: pointer;
    border: none;
    background: white;
    box-sizing: border-box;
}
.__stepper:focus, .__activeStep{
    border-bottom: 2px solid #ED7122;
    font-size: 1.7rem;
}
.__activeStep{
    border-bottom: 2px solid #ED7122;
}
.fileInput {
    display: none;
  }

  .fileLabel {
    border: 1px solid #ED7122;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: 30px;
    padding: 5px;
    color: #ED7122;
    border-radius: 4px;
  }
  
  .fileLabel:hover {
    background-color: #ED7122;
    color: whitesmoke;
  }
  
  .fileContent {
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
  }
  /* /**import de fichier */
  .__styleDisplayImport{
      display: block;
      background: rgba(128, 128, 128, 0.1);
      width: 100%;
      height: 300px;
      border: 1px solid rgba(128, 128, 128, 0.3);
      border-style: dotted solid;
      text-align: center;
      cursor: pointer;
  }
  .cadreDisplayImage{
      display: block;
      width: 70px;
      height: 70px;
      border-radius:50%;
      margin: 10% auto 15px auto;
      border: 1px solid rgba(0,0,0,0.1);
      background: rgba(128, 128, 128, 0.15);
      overflow: hidden;
  }

  .Style__imgImport{
      width: 70%;
      height: 70%;
      object-fit: cover;
      -o-object-fit: cover;
      padding: 15% 5%;
  }
  .Style__imgImport2{
    width: 70%;
    height: 70%;
    color: rgba(0,0,0,0.7);
    object-fit: cover;
    -o-object-fit: cover;
    padding: 5%;
  }
.styleLink__{
    text-decoration: none;
    color: black;
}
.style__text {
    height: 5vh;
    font-size: 16px;
  }
  
  .style_formulaire {
    left: 0px;
    width: 130px;
    background: #ED7122 0% 0% no-repeat padding-box;
    box-shadow: 0px 14px 13px #00000017;
    opacity: 1;
    border-radius: 80px;
    position: relative;
    margin-bottom: 87px;
    margin-left: auto;
    margin-right: auto;
    top: 35px;
  }
  .styleRadiusBtn{
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background: #ED7122;
      box-shadow: 0px 14px 13px #00000017;
      opacity: 1;
      color: white;
      text-align: center;
      margin-top: 20%;
      overflow: hidden;
      padding: 20px;
      cursor: pointer;
      box-sizing: border-box;
  }
  .styleColorTitre{
      color: #ED7122;
      font-weight: normal;
      font-size: 1.8rem;
  }

  .__styleMessErreur{
      color: red;
      margin: 10px 5px 0 5px;
      width: 100%;
      height: auto;
      font-size: 1.2rem;
      font-style: italic;
  }
  .colorModal{
      color: #ED7122;
  }
  .annexeStyle{
      width: 150px;
      height: auto;
      border-radius: 5px;
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
      display: inline-block;
      margin: 5px;
      padding: 5px;
  }
  .contentAnnexe{
      display: block;
      width: 100%;
  }
  .contentSearch{
      width: 350px;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5px;
      background: rgba(0, 0, 0, 0.05);
      margin: 15px 0 10px 0;
      border-radius: 5px;
  }
  .contentSearch input, .contentSearchNeed input{
      width: 100%;
      height: 20px;
      padding: 5px;
      border-radius: 5px;
      outline: none;
      border: none;
      background: transparent;
      font-size: 15px;
  }
  .contentSearchNeed{
      margin: 10px 20px 10px 0;
      height: auto;
      width: 400px;
      padding: 5px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
  }
  .contentSearchNeed input{
      margin: 5px;
  }
