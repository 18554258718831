.firstContainer {
    background: #f7fafc;
    opacity: 1;
    height: auto;
  }
  
  .details__style {
    margin-left: 25px;
    margin-top: -8px;
  }
  
  .info__style {
    float: right;
    margin-left: 15px;
  }
  
  .inputForm {
    width: 476px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #4d4f5c;
    opacity: 1;
  }
  
  .infoDetails__style {
    margin-left: auto;
  }
  
  .cadreTwo__style {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 260px;
  }
  
  .cadre__content {
    border: 1px solid orange;
    padding: 10px 15px 10px 20px;
  }
  
  .cadreOne__style {
    border: 1px solid green;
    padding: 10px 15px 10px 20px;
    margin: 20px 0px 20px 0px;
  }
  
  .position__questionnaire {
    display: flex;
    justify-content: center;
    margin: 35px 0px 35px 0px;
    font-size: x-large;
  }
  
  .input__style {
    width: 285px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #4d4f5c;
    font-style: italic;
    opacity: 0.6;
  }
  
  .textArea__style {
    width: 285px;
    height: 130px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #4d4f5c;
    border-radius: 4px;
    padding: 10px;
  }
  
  .content {
    border: 1px solid orange;
    width: 270px;
    height: 100px;
    padding: 5px 5px 9px 15px;
    font-size: smaller;
    border-radius: 4px;
    background-color: white;
  }
  
  .dispositifButton {
    position: relative;
    top: 20px;
  }
  
  .dispositifButtonInput {
    margin: 0 0 0 -2px;
  }
  
  .style__BoutonNext {
    position: relative;
    top: 5px;
  }
  
  .ImagePageWhite {
    width: 240px;
    height: 240px;
    margin: auto;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .__questionnaireText {
    width: 210px;
    text-align: center;
    letter-spacing: 0px;
    color: #4d4d4d;
    font-size: x-large;
    margin: auto;
  }
  
  .__conclusionStyle {
    position: relative;
    top: 50px;
    left: 10px;
  }
  
  .__descriptionStyle {
    position: relative;
    top: 25px;
    left: 10px;
  }
  
  .navigationStyle:hover {
    background-color: #fff;
    color: orange;
    border-radius: 50px;
    cursor: pointer;
  }
  
  .styleDeleteQuestion {
    position: relative;
    top: 3px;
    left: -18px;
    border: none;
    cursor: pointer;
  }
  
  .styleDeleteQuestion:hover {
    background-color: ghostwhite;
    border-radius: 4px;
  }
  
  .HeaderListQuestion{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 1px;
    background: rgba(0, 0, 0, 0.05);
    margin: 10px 0px;
  }
  .TitleQuestions, .ActionsQuestions{
    display: flex;
    padding: 5px 0;
  }
  .TitleQuestions h4{
    margin-right: 7px;
    font-size: 16px;
    font-weight: normal;
    color: #e5631e;
  }
  .TextQuestions p{
    width: 100%;
    padding: 2px 7px;
  }
  .Green_btn, .FalseGreen_btn{
    background: #2DCE98;
    color: white;
    border-radius: 16px;
    font-size: 10px;
    padding: 0 7px;
    width: 101px;
    height: 22px;
    border: none;
    margin: 0 10px;
  }
  .FalseGreen_btn{
    background: rgba(0, 0, 0, 0.25);
    text-align: center;
    padding-top: 4px;
  }
  .ListeQuestion{
    margin: 15px 0;
  }
  .reponseQuestion{
    width: 90%;
    display: block;
    margin: 10px 0 15px 2%;
    padding: 7px 5px;
    border: 1px solid #2DCE98;
    background: #F2F2F2;
  }
  .GreenReponse{
    color: #2DCE98;
    font-weight: bold;
    font-size: 16px;
    margin: 2px 0;
  }
  .conclusionQuestion{
    width: 90%;
    display: block;
    margin: 10px 0 20px 0;
    padding: 7px 5px;
    border: 1px solid #ED7122;
    background: #F2F2F2;
  }
  .colorTextConclsuion{
    color: #ED7122;
    font-size: 15px;
    margin: 2px 0;
  }
  .updateConclusion_{
    width: 100%;
    height: auto;
    display: block;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #e5631e;
  }

  .headerQuest{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    background-color:  #F2F2F2;
    padding: 5px 0;
  }
  .hearderCadreQuest{
    display: flex;
    width: auto;
    height: auto;
    color: #ED7122;
    margin: 0 5px;
  }
  .hearderCadreQuest .span2{
    border-left: 3px solid #ED7122;
    font-size: 12px;
    border-radius: 1px;
    padding: 3px 5px;
    margin-left: 5px;
    width: auto;
    text-align: center;
    margin: 1px 2px;
  }
  .haederRepondreContent{
    display: flex;
    width: auto;
    height: auto;
  }
  .cadreButtonRepond{
    background-color: #2DCE98;
    width: 100px;
    color: white;
    font-size: 12px;
    text-align: center;
    padding-top: 2px;
    margin: 0px 3px;
    border-radius: 50px;
  }
  .cadreAction{
    display: flex;
    justify-content: center;
    color: black;
    font-size: 12px;
  }
  .myQuest{
    margin: 10px 20px;
    color: black;
    font-size: 16px;
  }
  .cadreDescritif{
    width: 90%;
    height: auto;
    display: block;
    border: 1px solid #2DCE98;
    background-color: #F2F2F2;
    padding: 10px;
    margin-left: 20px;
  }
  .cadreDescritif h3{
    color: #2DCE98;
    font-size: 16px;
    margin-bottom: 5px;
    text-align: left;
  }
  .cadreDescritif h4{
    color: black;
    font-size: 16px;
    text-align: left;
    font-weight: normal;
  }
  .cadreDescritif p{
    color: black;
    font-size: 15px;
    text-align: left;
    width: auto;
  }
  .cadreConclusion{
    background-color: #F2F2F2;
    border: 1px solid #ED7122;
    margin: 20px 3px;
    width: 90%;
    text-align: left;
    padding: 10px;
  }
  .cadreConclusion span{
    font-size: 16px;
    color: #ED7122;
    margin-bottom: 2px;
  }
  .cadreConclusion p{
    color: black;
    font-size: 15px;
    width: auto;
  }
  .cadreInputCheck{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgb(237, 113, 34, 0.2);
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
  }