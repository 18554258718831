.barCharStyle{
    height: auto;
    padding: 10% 10px;
}
.barCharStyleExercice {
    height: auto;
    padding: 10px;
}
.budgetStyle, .factureStyle{
    height: auto;
    padding: 10% 10px;
}
.barCharStyleSpace{
    padding: 10% 10px;
    height: auto;
}
.budgetStyle{
    position: relative;
}
.budgetStyle .total_{
    position: absolute;
    bottom: 0;
    right: 10px;
    width: 100%;
    height: auto;
    text-align: right;
    padding: 7px 0;
    font-size: 1.4rem;
    font-weight: 1000;
}