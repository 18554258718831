.__buttonSimple, .__buttonOrange{
    outline: none;
    width: auto;
    padding: 5px 15px;
    height: auto;
    font-size: 1.3rem;
    margin: 0 5px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
}
.__buttonSimple p, .__buttonOrange p{
    margin-top: 5px;
}
.__buttonWhiteImport{
    outline: none;
    width: 55px;
    padding: 7px 5px;
    height: auto;
    font-size: 1.2rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
    background: transparent;
    color: #ED7122;
    border: 1px solid #ED7122;
}
.__buttonOrange{
    background: #ED7122;
    color: white;
    border: none;
}
.__buttonOrangeMail{
  background: #ED7122;
  color: white;
  border: none;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  font-size: 1.6rem;
  outline: none;
  padding: 5px 15px;
  cursor: pointer;
}
.__buttonSimple{
    background: transparent;
    color: #ED7122;
    border: 1px solid #ED7122;
}
.styleButton{
    width: auto;
    height: auto;
    display: flex;
}

.orange__button {
    color: white;
    width: 175px;
    height: 35px;
    background: #ED7122;
    box-shadow: 0px 3px 6px #2c28281c;
    border-radius: 5px;
    opacity: 1;
    font-size: 12px;
    cursor: pointer;
    border: none;
  }
  .orange__buttonImport {
    color: black;
    width: 45%;
    height: 40px;
    background: rgba(237, 113, 34,0.5);
    box-shadow: 0px 3px 6px #2c28281c;
    border-radius: 5px;
    opacity: 1;
    font-size: 12px;
    cursor: pointer;
    border: none;
  }
  .Link__buttonImport{
    width: 45%;
    height: 40px;
  }
  .orange__buttonImport1{
    width: 100%;
    height: 100%;
    background: rgba(237, 113, 34,0.5);
    box-shadow: 0px 3px 6px #2c28281c;
    border-radius: 5px;
    opacity: 1;
    font-size: 12px;
    cursor: pointer;
    border: none;
    color: black;
  }
  .white__buttonImport {
    color: black;
    width: 45%;
    height: 40px;
    border: 1px solid #ED7122;
    box-shadow: 0px 3px 6px #2c28281c;
    border-radius: 5px;
    opacity: 1;
    font-size: 12px;
    cursor: pointer;
    border: none;
  }
  .__listModelButton{
    color: #ED7122;
    width: auto;
    height: auto;
    padding: 10px 2.1rem;
    background: rgba(237, 113, 34,0.07);
    border-radius: 20px;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    border: 1px solid #ED7122;
  }
  
  .white__button {
    margin-right: 10px;
    color: #ED7122;
    width: 175px;
    height: 35px;
    box-shadow: 0px 3px 6px #2c28281c;
    border: 1px solid #ED7122;
    border-radius: 5px;
    opacity: 1;
    font-size: 12px;
    transition: 0.4s;
    cursor: pointer;
    border: 1px solid #ED7122;
  }

  .white__buttonModale {
    color: #ED7122;
    width: 100px;
    height: 35px;
    box-shadow: 0px 3px 6px #2c28281c;
    border-radius: 5px;
    font-size: 1.3rem;
    transition: 0.4s;
    cursor: pointer;
    border: 1px solid #ED7122;
  }
  .orange__buttonModale, .success__buttonModale {
    color: white;
    width: 100px;
    height: 35px;
    box-shadow: 0px 3px 6px #2c28281c;
    border-radius: 5px;
    opacity: 1;
    font-size: 1.3rem;
    transition: 0.4s;
    cursor: pointer;
    border: none;
  }
  .success__buttonModale{
    background: green;
  }
  .orange__buttonModale{
    background: #ED7122;
  }
  .reponseDataStyle{
    font-size: 16px;
    font-weight: 300;
  }
  .hearderModalReponse{
    color: #ED7122;
  }
  .contentCommentModale{
    height: 100px;
    border-radius: 3px;
    padding: 5px;
    box-sizing: border-box;
  }
.MuiFormControlLabel-label{
  font-size: 20px;
}