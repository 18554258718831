.propspectHeaderStyle p{
    margin-top: 10px;
}
.cadreListProspect{
    padding-top: 10px;
}

.root {
    flex-grow: 1;
    width: "100%";
  }
  
  .style_formulaire {
    left: 0px;
    width: 130px;
    background: #ED7122 0% 0% no-repeat padding-box;
    box-shadow: 0px 14px 13px #00000017;
    opacity: 1;
    border-radius: 80px;
    position: relative;
    margin-bottom: 87px;
    margin-left: auto;
    margin-right: auto;
    top: 35px;
  }
  
  .color__text {
    color: #ED7122;
  }
  .colorTextNeedTitle{
    color: #8898AA;
    font-weight: 400;
    font-size: 16px;
  }
  
  .style__text {
    height: 5vh;
    font-size: 16px;
  }
  
  .requiredField::after {
    content: "*";
    color: red;
  }
  
  .styleMsg {
    font-size: 20px;
    text-align: center;
  }
  
  .sidebarMain {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #2c28281c;
  }
  
  .sidebarNav {
    background-color: #4D4F5C;
    height: auto;
    color: #fff;
    border-radius: 15px 0px 0px 15px;
    box-shadow: 0px 3px 6px #2c28281c;
  }
  
  .sidebarNav-mod {
    margin-left: 15px;
  }
  
  .activeTabStyle {
    font-weight: normal;
    background-color: #ffffff;
    color: #ED7122;
    width: 100%;
    border-radius: 20px 0px 0px 20px;
    padding-left: 15px;
    display: flex;
    text-align: left;
    border: none;
    margin: 0 10px;
  }
  .btnRouge {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #ED7122;
    margin-top: 2px;
    margin-right: 6px;
    margin-left: 10px;
  }
  
  .contentTabs {
    padding: 10px 15px 8px 5px;
    cursor: pointer;
  }
  
  .color__textTabs {
    color: white;
    background: #4D4F5C;
    border: none;
    text-align: left;
  }
  
  .styleCode {
    width: 110px;
    background-color: white;
    font-size: x-small;
    color: #ED7122;
    padding: 1px;
    text-align: center;
    border-radius: 5px;
    margin-top: 3px;
    margin-left: 13px;
  }
  
  .footerContent {
    margin: 40px auto;
  }
  
  .footerContentRight {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 25px;
    margin-bottom: 20px;
    position: relative;
    top: 25px;
  }
  
  .fileLabelPicture {
    border: 1px solid #ED7122;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: 23px;
    padding: 5px;
    color: #ED7122;
    border-radius: 4px;
  }
  
  .fileLabelPicture:hover {
    background-color: #ED7122;
    color: whitesmoke;
  }
.__styleTitleStepper{
  font-weight: normal;
  font-size: 1.6rem;
}
.__styleBuutonReturnForm{
  display: flex;
  margin: 0;
  padding: 0;
  text-align: right;
}
.__contentImgProspect{
  display: flex;
  justify-content: left;
  width: 100%;
  height: auto;
}
.__contentImgProspect span{
  padding-top: 7px;
  margin-left: 7%;
}
.prospectImg{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(128, 128, 128, 0.3);
  overflow: hidden;
}
.prospectImg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}