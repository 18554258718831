/* acajou */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_acajou{
    background: #88421D !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_acajou{
    background: #88421D !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_acajou{
    background: #88421D !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_acajou .dhx_header, .dhx_cal_event .dhx_title{
    background: #88421D !important;
}
.colorIntervenant_acajou{
    background: #88421D !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* ambre */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_ambre{
    background: #F0c300 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_ambre{
    background: #F0c300 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_ambre{
    background: #F0c300 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_ambre .dhx_header, .dhx_cal_event .dhx_title{
    background: #F0c300 !important;
}
.colorIntervenant_ambre{
    background: #F0c300 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* anthracite */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_anthracite{
    background: #293133 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_anthracite{
    background: #293133 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_anthracite{
    background: #293133 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_anthracite .dhx_header, .dhx_cal_event .dhx_title{
    background: #293133 !important;
}
.colorIntervenant_anthracite{
    background: #293133 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* argent */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_argent{
    background: #c0c0c0 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_argent{
    background: #c0c0c0 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_argent{
    background: #c0c0c0 !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_argent .dhx_header, .dhx_cal_event .dhx_title{
    background: #c0c0c0 !important;
    color: black !important;
}
.colorIntervenant_argent{
    background: #c0c0c0 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* aubergine */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_aubergine{
    background: #370028 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_aubergine{
    background: #370028 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_aubergine{
    background: #370028 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_aubergine .dhx_header, .dhx_cal_event .dhx_title{
    background: #370028 !important;
}
.colorIntervenant_argent{
    background: #c0c0c0 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* auburn */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_auburn{
    background: #622725 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_auburn{
    background: #622725 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_auburn{
    background: #622725 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_auburn .dhx_header, .dhx_cal_event .dhx_title{
    background: #622725 !important;
}
.colorIntervenant_auburn{
    background: #622725 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* azur */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_azur{
    background: #007fff !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_azur{
    background: #007fff !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_azur{
    background: #007fff !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_azur .dhx_header, .dhx_cal_event .dhx_title{
    background: #007fff !important;
}
.colorIntervenant_azur{
    background: #007fff !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* beige */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_beige{
    background: #f5f5dc !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_beige{
    background: #f5f5dc !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_beige{
    background: #f5f5dc !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_beige .dhx_header, .dhx_cal_event .dhx_title{
    background: #f5f5dc !important;
    color: black !important;
}
.colorIntervenant_beige{
    background: #f5f5dc !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* bistre */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_bistre{
    background: #856d4d !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_bistre{
    background: #856d4d !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_bistre{
    background: #856d4d !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_bistre .dhx_header, .dhx_cal_event .dhx_title{
    background: #856d4d !important;
}
.colorIntervenant_bistre{
    background: #856d4d !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* bleu */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_bleu{
    background: #005c96 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_bleu{
    background: #005c96 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_bleu{
    background: #005c96 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_bleu .dhx_header, .dhx_cal_event .dhx_title{
    background: #005c96 !important;
}
.colorIntervenant_bleu{
    background: #005c96 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* bleu_canard */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_bleu_canard{
    background: #048b9a !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_bleu_canard{
    background: #048b9a !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_bleu_canard{
    background: #048b9a !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_bleu_canard .dhx_header, .dhx_cal_event .dhx_title{
    background: #048b9a !important;
}
.colorIntervenant_bleu_canard{
    background: #048b9a !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* bleu_roi */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_bleu_roi{
    background: #318ce7 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_bleu_roi{
    background: #318ce7 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_bleu_roi{
    background: #318ce7 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_bleu_roi .dhx_header, .dhx_cal_event .dhx_title{
    background: #318ce7 !important;
}
.colorIntervenant_bleu_roi{
    background: #318ce7 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* bois */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_bois{
    background: #deb887 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_bois{
    background: #deb887 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_bois{
    background: #deb887 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_bois .dhx_header, .dhx_cal_event .dhx_title{
    background: #deb887 !important;
}
.colorIntervenant_bois{
    background: #deb887 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* bordeaux */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_bordeaux{
    background: #6d071a !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_bordeaux{
    background: #6d071a !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_bordeaux{
    background: #6d071a !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_bordeaux .dhx_header, .dhx_cal_event .dhx_title{
    background: #6d071a !important;
}
.colorIntervenant_bordeaux{
    background: #6d071a !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* brique */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_brique{
    background: #842e1b !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_brique{
    background: #842e1b !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_brique{
    background: #842e1b !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_brique .dhx_header, .dhx_cal_event .dhx_title{
    background: #842e1b !important;
}
.colorIntervenant_brique{
    background: #842e1b !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* bronze */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_bronze{
    background: #614e1a !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_bronze{
    background: #614e1a !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_bronze{
    background: #614e1a !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_bronze .dhx_header, .dhx_cal_event .dhx_title{
    background: #614e1a !important;
}
.colorIntervenant_bronze{
    background: #614e1a !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* cafe */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_cafe{
    background: #785e2f !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_cafe{
    background: #785e2f !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_cafe{
    background: #785e2f !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_cafe .dhx_header, .dhx_cal_event .dhx_title{
    background: #785e2f !important;
}
.colorIntervenant_cafe{
    background: #785e2f !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* caramel */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_caramel{
    background: #7e3300 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_caramel{
    background: #7e3300 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_caramel{
    background: #7e3300 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_caramel .dhx_header, .dhx_cal_event .dhx_title{
    background: #7e3300 !important;
}
.colorIntervenant_caramel{
    background: #7e3300 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* champagne */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_champagne{
    background: #fbf2b7 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_champagne{
    background: #fbf2b7 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_champagne{
    background: #fbf2b7 !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_champagne .dhx_header, .dhx_cal_event .dhx_title{
    background: #fbf2b7 !important;
    color: black !important;
}
.colorIntervenant_champagne{
    background: #fbf2b7 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* chocolat */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_chocolat{
    background: #45322e !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_chocolat{
    background: #45322e !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_chocolat{
    background: #45322e !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_chocolat .dhx_header, .dhx_cal_event .dhx_title{
    background: #45322e !important;
}
.colorIntervenant_chocolat{
    background: #45322e !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* corail */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_corail{
    background: #ff7f50 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_corail{
    background: #ff7f50 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_corail{
    background: #ff7f50 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_corail .dhx_header, .dhx_cal_event .dhx_title{
    background: #ff7f50 !important;
}
.colorIntervenant_corail{
    background: #ff7f50 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* cuivre */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_cuivre{
    background: #b36700 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_cuivre{
    background: #b36700 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_cuivre{
    background: #b36700 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_cuivre .dhx_header, .dhx_cal_event .dhx_title{
    background: #b36700 !important;
}
.colorIntervenant_cuivre{
    background: #b36700 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* cyan */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_cyan{
    background: #00ffff !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_cyan{
    background: #00ffff !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_cyan{
    background: #00ffff !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_cyan .dhx_header, .dhx_cal_event .dhx_title{
    background: #00ffff !important;
}
.colorIntervenant_cyan{
    background: #00ffff !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* dore */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_dore{
    background: #ffc961 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_dore{
    background: #ffc961 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_dore{
    background: #ffc961 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_dore .dhx_header, .dhx_cal_event .dhx_title{
    background: #ffc961 !important;
}
.colorIntervenant_dore{
    background: #ffc961 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* ecru */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_ecru{
    background: #fefee0 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_ecru{
    background: #fefee0 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_ecru{
    background: #fefee0 !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_ecru .dhx_header, .dhx_cal_event .dhx_title{
    background: #fefee0 !important;
    color: black !important;
}
.colorIntervenant_ecru{
    background: #fefee0 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* emeraude */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_emeraude{
    background: #01d758 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_emeraude{
    background: #01d758 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_emeraude{
    background: #01d758 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_emeraude .dhx_header, .dhx_cal_event .dhx_title{
    background: #01d758 !important;
}
.colorIntervenant_emeraude{
    background: #01d758 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* fuchsia */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_fuchsia{
    background: #ff00ff !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_fuchsia{
    background: #ff00ff !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_fuchsia{
    background: #ff00ff !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_fuchsia .dhx_header, .dhx_cal_event .dhx_title{
    background: #ff00ff !important;
}
.colorIntervenant_fuchsia{
    background: #ff00ff !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* grenat */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_grenat{
    background: #fe0b14 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_grenat{
    background: #fe0b14 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_grenat{
    background: #fe0b14 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_grenat .dhx_header, .dhx_cal_event .dhx_title{
    background: #fe0b14 !important;
}
.colorIntervenant_grenat{
    background: #fe0b14 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* gris */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_gris{
    background: #888888 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_gris{
    background: #888888 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_gris{
    background: #888888 !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_gris .dhx_header, .dhx_cal_event .dhx_title{
    background: #888888 !important;
    color: black !important;
}
.colorIntervenant_gris{
    background: #888888 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* indigo */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_indigo{
    background: #4b0082 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_indigo{
    background: #4b0082 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_indigo{
    background: #4b0082 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_indigo .dhx_header, .dhx_cal_event .dhx_title{
    background: #4b0082 !important;
}
.colorIntervenant_indigo{
    background: #4b0082 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* ivoire */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_ivoire{
    background: #fffff0 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_ivoire{
    background: #fffff0 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_ivoire{
    background: #fffff0 !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_ivoire .dhx_header, .dhx_cal_event .dhx_title{
    background: #fffff0 !important;
    color: black !important;
}
.colorIntervenant_ivoire{
    background: #fffff0 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* jade */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_jade{
    background: #87e990 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_jade{
    background: #87e990 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_jade{
    background: #87e990 !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_jade .dhx_header, .dhx_cal_event .dhx_title{
    background: #87e990 !important;
    color: black !important;
}
.colorIntervenant_jade{
    background: #87e990 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* jaune */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_jaune{
    background: #ffcf39 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_jaune{
    background: #ffcf39 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_jaune{
    background: #ffcf39 !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_jaune .dhx_header, .dhx_cal_event .dhx_title{
    background: #ffcf39 !important;
    color: black !important;
}
.colorIntervenant_jaune{
    background: #ffcf39 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* kaki */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_kaki{
    background: #f0e68c !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_kaki{
    background: #f0e68c !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_kaki{
    background: #f0e68c !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_kaki .dhx_header, .dhx_cal_event .dhx_title{
    background: #f0e68c !important;
    color: black !important;
}
.colorIntervenant_kaki{
    background: #f0e68c !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* lavande */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_lavande{
    background: #e6e6fa !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_lavande{
    background: #e6e6fa !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_lavande{
    background: #e6e6fa !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_lavande .dhx_header, .dhx_cal_event .dhx_title{
    background: #e6e6fa !important;
    color: black !important;
}
.colorIntervenant_lavande{
    background: #e6e6fa !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* lie_de_vin */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_lie_de_vin{
    background: #824348 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_lie_de_vin{
    background: #824348 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_lie_de_vin{
    background: #824348 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_lie_de_vin .dhx_header, .dhx_cal_event .dhx_title{
    background: #824348 !important;
}
.colorIntervenant_lie_de_vin{
    background: #824348 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* lin */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_lin{
    background: #f4f0e6 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_lin{
    background: #f4f0e6 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_lin{
    background: #f4f0e6 !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_lin .dhx_header, .dhx_cal_event .dhx_title{
    background: #f4f0e6 !important;
    color: black !important;
}
.colorIntervenant_lin{
    background: #f4f0e6 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* magenta */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_magenta{
    background: #ff00ff !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_magenta{
    background: #ff00ff !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_magenta{
    background: #ff00ff !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_magenta .dhx_header, .dhx_cal_event .dhx_title{
    background: #ff00ff !important;
}
.colorIntervenant_magenta{
    background: #ff00ff !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* marron */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_marron{
    background: #7f3b1e !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_marron{
    background: #7f3b1e !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_marron{
    background: #7f3b1e !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_marron .dhx_header, .dhx_cal_event .dhx_title{
    background: #7f3b1e !important;
}
.colorIntervenant_marron{
    background: #7f3b1e !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* mauve */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_mauve{
    background: #d473d4 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_mauve{
    background: #d473d4 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_mauve{
    background: #d473d4 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_mauve .dhx_header, .dhx_cal_event .dhx_title{
    background: #d473d4 !important;
}
.colorIntervenant_mauve{
    background: #d473d4 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* miel */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_miel{
    background: #a98307 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_miel{
    background: #a98307 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_miel{
    background: #a98307 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_miel .dhx_header, .dhx_cal_event .dhx_title{
    background: #a98307 !important;
}
.colorIntervenant_miel{
    background: #a98307 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* moka */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_moka{
    background: #827775 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_moka{
    background: #827775 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_moka{
    background: #827775 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_moka .dhx_header, .dhx_cal_event .dhx_title{
    background: #827775 !important;
}
.colorIntervenant_moka{
    background: #827775 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* moutarde */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_moutarde{
    background: #ffdb58 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_moutarde{
    background: #ffdb58 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_moutarde{
    background: #ffdb58 !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_moutarde .dhx_header, .dhx_cal_event .dhx_title{
    background: #ffdb58 !important;
    color: black !important;
}
.colorIntervenant_moutarde{
    background: #ffdb58 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* noire */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_noire{
    background: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_noire{
    background: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_noire{
    background: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_noire .dhx_header, .dhx_cal_event .dhx_title{
    background: black !important;
}
.colorIntervenant_noire{
    background:black !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* ocre */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_ocre{
    background: #dd985c !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_ocre{
    background: #dd985c !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_ocre{
    background: #dd985c !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_ocre .dhx_header, .dhx_cal_event .dhx_title{
    background: #dd985c !important;
}
.colorIntervenant_ocre{
    background: #dd985c !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* orange */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_orange{
    background: #ff8e06 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_orange{
    background: #ff8e06 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_orange{
    background: #ff8e06 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_orange .dhx_header, .dhx_cal_event .dhx_title{
    background: #ff8e06 !important;
}
.colorIntervenant_orange{
    background: #ff8e06 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* parme */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_parme{
    background: #cfa0e9 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_parme{
    background: #cfa0e9 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_parme{
    background: #cfa0e9 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_parme .dhx_header, .dhx_cal_event .dhx_title{
    background: #cfa0e9 !important;
}
.colorIntervenant_parme{
    background: #cfa0e9 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* pourpre */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_pourpre{
    background: #9e0e40 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_pourpre{
    background: #9e0e40 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_pourpre{
    background: #9e0e40 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_pourpre .dhx_header, .dhx_cal_event .dhx_title{
    background: #9e0e40 !important;
}
.colorIntervenant_pourpre{
    background: #9e0e40 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* prune */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_prune{
    background: #811453 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_prune{
    background: #811453 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_prune{
    background: #811453 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_prune .dhx_header, .dhx_cal_event .dhx_title{
    background: #811453 !important;
}
.colorIntervenant_prune{
    background: #811453 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* rose */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_rose{
    background: #ffacc8 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_rose{
    background: #ffacc8 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_rose{
    background: #ffacc8 !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_rose .dhx_header, .dhx_cal_event .dhx_title{
    background: #ffacc8 !important;
    color: black !important;
}
.colorIntervenant_rose{
    background: #ffacc8 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* rouge */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_rouge{
    background: #ff0016 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_rouge{
    background: #ff0016 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_rouge{
    background: #ff0016 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_rouge .dhx_header, .dhx_cal_event .dhx_title{
    background: #ff0016 !important;
}
.colorIntervenant_rouge{
    background: #ff0016 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* sable */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_sable{
    background: #e0cda9 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_sable{
    background: #e0cda9 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_sable{
    background: #e0cda9 !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_sable .dhx_header, .dhx_cal_event .dhx_title{
    background: #e0cda9 !important;
    color: black !important;
}
.colorIntervenant_sable{
    background: #e0cda9 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* safran */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_safran{
    background: #f3d617 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_safran{
    background: #f3d617 !important;
    color: black !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_safran{
    background: #f3d617 !important;
    color: black !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_safran .dhx_header, .dhx_cal_event .dhx_title{
    background: #f3d617 !important;
    color: black !important;
}
.colorIntervenant_safran{
    background: #f3d617 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* saumon */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_saumon{
    background: #fa8072 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_saumon{
    background: #fa8072 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_saumon{
    background: #fa8072 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_saumon .dhx_header, .dhx_cal_event .dhx_title{
    background: #fa8072 !important;
}
.colorIntervenant_saumon{
    background: #fa8072 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* taupe */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_taupe{
    background: #463f32 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_taupe{
    background: #463f32 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_taupe{
    background: #463f32 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_taupe .dhx_header, .dhx_cal_event .dhx_title{
    background: #463f32 !important;
}
.colorIntervenant_taupe{
    background: #463f32 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* turquoise */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_turquoise{
    background: #40e0d0 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_turquoise{
    background: #40e0d0 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_turquoise{
    background: #40e0d0 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_turquoise .dhx_header, .dhx_cal_event .dhx_title{
    background: #40e0d0 !important;
}
.colorIntervenant_turquoise{
    background: #40e0d0 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* vermeil */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_vermeil{
    background: #ff0921 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_vermeil{
    background: #ff0921 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_vermeil{
    background: #ff0921 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_vermeil .dhx_header, .dhx_cal_event .dhx_title{
    background: #ff0921 !important;
}
.colorIntervenant_vermeil{
    background: #ff0921 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* vermillon */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_vermillon{
    background: #d9603b !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_vermillon{
    background: #d9603b !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_vermillon{
    background: #d9603b !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_vermillon .dhx_header, .dhx_cal_event .dhx_title{
    background: #d9603b !important;
}
.colorIntervenant_vermillon{
    background: #d9603b !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* vert */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_vert{
    background: #4fc031 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_vert{
    background: #4fc031 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_vert{
    background: #4fc031 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_vert .dhx_header, .dhx_cal_event .dhx_title{
    background: #4fc031 !important;
}
.colorIntervenant_vert{
    background: #4fc031 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* violet */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_violet{
    background: #74238a !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_violet{
    background: #74238a !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_violet{
    background: #74238a !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_violet .dhx_header, .dhx_cal_event .dhx_title{
    background: #74238a !important;
}
.colorIntervenant_violet{
    background: #74238a !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}
/* wenge */
.dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.event_wenge{
    background: #fbf2b7 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_end.event_wenge{
    background: #fbf2b7 !important;
}
.dhx_cal_event_line.dhx_cal_event_line_start.event_wenge{
    background: #fbf2b7 !important;
}
.dhx_cal_event .dhx_body, .dhx_cal_event .dhx_footer, .dhx_cal_event.event_wenge .dhx_header, .dhx_cal_event .dhx_title{
    background: #fbf2b7 !important;
}
.colorIntervenant_wenge{
    background: #fbf2b7 !important;
    width: 12px;
    height: 12px;
    margin: 3px 5px;
    border-radius: 50%;
}