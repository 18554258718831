.sidebarTextRich {
    top: 60px;
    width: 167px;
    background: #353535;
    height: 100vh;
    color: #fff;
    border-radius: 15px 0px 0px 15px;
  }
  .menu {
    padding: 0 0 0 10px;
    color: rgb(255, 255, 255);
    top: 50%;
  }
  .containerTextRich {
    padding: 10px 15px 8px 5px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
  }
  .containerTextRich:hover,
  .edition__style:hover {
    background: #fff;
    color: #ED7122;
    border-radius: 15px 0px 0px 15px;
  }
  .containerRichText {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-right: 25px;
  }
  .MenuActive {
    font-weight: bold;
    color: #ED7122;
    background: #fff;
    border-radius: 16px 0px 0px 16px;
  }
  .envoyer {
    display: flex;
    padding-top: 30px;
    font-size: 10px;
  }
  .btnRouge {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #ED7122;
    margin-top: 2px;
    margin-right: 6px;
    margin-left: 10px;
  }
  .titre {
    font-size: 17px;
    margin-bottom: 25px;
    margin-top: 20px;
    margin-left: 10px;
  }
  .btnOrange {
    color: white;
    width: 100px;
    height: 35px;
    background: #ED7122;
    box-shadow: 0px 3px 6px #2c28281c;
    border-radius: 5px;
    opacity: 1;
    font-size: 12px;
  }
  .btnWhite {
    margin-right: 10px;
    color: #ED7122;
    width: 100px;
    height: 35px;
    box-shadow: 0px 3px 6px #2c28281c;
    border: 1px solid #ED7122;
    border-radius: 5px;
    opacity: 1;
    font-size: 12px;
  }
  .paper {
    border-radius: 25px 0px 0px 25px;
  }
  .flex-container {
    display: flex;
  }
  .avatar {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .position {
    justify-content: flex-end;
  }
  
  .style__importFile {
    margin-top: 20px;
    border-radius: 5px;
  }
  
  .style__buttonContent:hover {
    color: #fff;
    background-color: #ED7122;
    border-radius: 20px;
    transition: 0.4s;
  }
  
  .style__buttonContent {
    color: #ED7122;
    background-color: #fff;
  }
  
  .styleFirtContainer {
    background: #f7fafc 0% 0% no-repeat padding-box;
    opacity: 1;
    height: "auto";
  }
  
  .descriptionStyle {
    position: relative;
    top: 25px;
  }
  
  .conclusionStyle {
    position: relative;
    top: 50px;
  }
  
  .__dispositifButton {
    display: inline-flex;
    position: relative;
    top: 20px;
  }
  
  .StyleMissionSection_{
    display: flex;
    justify-content: space-between;
    width: auto;
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  .styleTreeItem{
    margin: 0;
    background: red;
  }
  .styleSousMenu{
    height: auto;
  }
  .StyleLabelMisssion{
    width: 60%;
  }
  .borderStyleText{
    border: 1px solid #ED7122;
  }
  .cadrePerformance{
    position: absolute;
    display: none;
    width: 150px;
    height: 80px;
    background: white;
    bottom: 5px;
    left: 0;
    border-radius: 5px;
    box-shadow: 0px 0 10px 0px rgba(237, 113, 34, 0.3);
    border: 1px solid rgba(237, 113, 34, 0.3);
    font-weight: 300;
    padding: 5px;
  }
  .cadrePerformance.activated{
    display: block;
  }
  .cadrePerformance h4{
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
    color: #ED7122;
  }
  .cadrePerformance p{
    font-size: 12px;
  }
  .closeOverPerf{
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }
  .cadreLeftSousMenu{
    background: #fdfdfd;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  }
  .styleCadrePdfMission{
    width: 100%;
    height: auto;
    background: #fdfdfd;
    border-radius: 7px;
  }
  .viewPdfStyleCadre{
    width: 100%;
    height: 550px;
    background: transparent;
  }
  .styleViewDate{
    width: 100%;
    height: auto;
    background: #fcfcfc;
    overflow: hidden;
    border-bottom: 1px solid rgba(237, 113, 34, 0.3);
    border-radius: 5px;
    text-align: center;
    padding: 10px 0;
  }
  .styleDatePlanning{
    font-size:30px !important;
  }
  .cadreListingPlanning{
    border-left: 1px solid rgba(237, 113, 34, 0.7);
    padding-left: 10px;
    width: 90%;
  }
  .gridListeTache{
    width: 100%;
    height: 350px;
    overflow: scroll;
    overflow-x: hidden;
  }
  .planningTextStyle{
    color: #4D4D4D;
    margin-left: 20px;
    margin-bottom: 5px;
  }
  .colorTextOrange{
    color: #ED7122;
  }
  .cadreTachesPlanningStyle{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(237, 113, 34, 0.1);
  }
  .barreTache{
    width: 10px;
    height: 2px;
    background-color: #ED7122;
  }
  .cadreIntervenantStyle{
    width: auto;
    height: auto;
    background-color: rgba(237, 113, 34, 0.05);
    border-radius: 3px;
    text-align: center;
    padding: 3px 5px;
    font-size: 12px;
    box-sizing: border-box;
    color: #ED7122;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .cadreListeTches{
    height: 600px;
    overflow: scroll;
    border-top: 1px solid rgba(237, 113, 34, 0.3);
    overflow-x: hidden;
  }
  .cadreTextRichQuill{
    height: 400px;
    border: none;
  }
  .quillHeight{
    height: 450px;
  }
  .lignBtnColor{
    border-bottom: 1px solid rgba(237, 113, 34, 0.3);
  }
  .styleFormatPdfColor{
    background-color: rgba(237, 113, 34, 0.1);
    color: #ED7122;
    width: auto;
    height: auto;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
  }