.modalPropection .MuiDialog-container::before {
  content: "";
  background-color: #ED7122;
  width: 380px;
  height: 70px;
  position: relative;
  left: 495px;
  top: -185px;
  margin: 48px;
  border-radius: 20px 20px 0px 0px;
}

.modalPropection .MuiDialog-container {
  height: 100%;
  position: absolute;
}

.modalDelete .MuiDialog-container::before {
  content: "";
  background-image: url("../../@shared/assets/images/imageModal.png");
  background-size: cover;
  width: 392px;
  height: 175px;
  position: relative;
  left: 480px;
  top: -85px;
  margin: 48px;
  z-index: 1;
  border-radius: 20px 20px 0px 0px;
}

.modalDelete .MuiDialog-container {
  height: 100%;
  position: absolute;
}

/* style import file */

.fileInput {
  display: none;
}

.fileLabel {
  border: 1px solid #ED7122;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 30px;
  padding: 5px;
  color: #ED7122;
  border-radius: 4px;
}

.fileLabel:hover {
  background-color: #ED7122;
  color: whitesmoke;
}

.fileContent {
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
}

.modalTaskAndActivite {
  width: 450px;
  margin-top: -30px;
}
.titleModalPlanning {
  color: #ED7122;
  text-align: left;
  font-weight: normal;
}

.modaleLabelStyle{
  font-size: 16px;
  margin: 5px 0;
}