.headerLanding {
  width: 100%;
  height: 100px;
  background: #172b4d;
  color: white;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  font-size: 15px;
  justify-content: space-between;
  overflow: hidden;
  z-index: 10000;
  padding: 0 10%;
  box-sizing: border-box;
}
.headerLanding .StyleLogo,
.footer-left .StyleLogo,
.overTextLogin .StyleLogo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.headerLanding .StyleLogo .logoLanding,
.footer-left .StyleLogo .logoLanding,
.overTextLogin .StyleLogo .logoLanding {
  background: #ed7122;
  width: 40px;
  height: 40px;
  overflow: hidden;
}
.logoLanding img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.headerLanding .StyleLogo h1,
.footer-left .StyleLogo h1,
.overTextLogin .StyleLogo h1 {
  color: #ed7122;
  font-size: 2rem;
  padding-left: 5px;
  letter-spacing: 0px;
}
.headerLanding nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
}
.headerLanding nav ul li {
  list-style: none;
  text-decoration: none;
  margin: 0 15px;
  letter-spacing: 0px;
}
.headerLanding nav ul li a {
  text-decoration: none;
  list-style: none;
  color: white !important;
}
.headerLanding nav ul li a:hover {
  color: #ed7122 !important;
  text-decoration: none;
  list-style: none;
}
.headerLanding nav ul li.activated {
  color: #ed7122 !important;
}
.headerLanding nav ul li button {
  width: 130px;
  height: 45px;
  background: #ed7122;
  border-radius: 3px;
  color: white;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

.styleContentLanding {
  width: 100%;
  height: auto;
  margin-top: 100px;
  overflow-x: hidden;
}

.styleBannerOfPresentation {
  width: 100%;
  height: 650px;
  display: block;
  overflow: hidden;
  position: relative;
}
.styleBannerOfPresentation img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.styleBannerOfPresentation .overImgBanner,
.contentImgcadrePlanning .overImgBanner,
.cadreBannerSolution .overImgBanner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #0000005a;
  box-sizing: border-box;
  text-align: center;
}
.styleBannerOfPresentation .overImgBanner,
.cadreBannerSolution .overImgBanner {
  padding: 15% 10% 0 10%;
}
.styleBannerOfPresentation .overImgBanner h1,
.overTextLogin h2 {
  color: white;
  font-size: 3.5rem;
}
.styleBannerOfPresentation .overImgBanner h1 span,
.overTextLogin h2 span {
  color: #ed7122;
}
.styleBannerOfPresentation .overImgBanner p {
  color: rgb(255, 255, 255, 0.9);
  font-size: 15px;
}
.styleBannerOfPresentation .overImgBanner button {
  border: none;
  width: 120px;
  height: 40px;
  color: white;
  border-radius: 3px;
  margin: 20px 0;
  background: #ed7122;
}
.styleBannierWhite {
  padding: 0 10%;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
}
.styleBannierWhite h1,
.content-offer h1 {
  color: #172b4d;
  font-size: 3rem;
  margin-top: 2%;
}
.secondeParagraphe p {
  color: #4d4f5c;
  font-size: 15px;
}
.contentCadrePlanning {
  margin: 5% 0;
  padding: 0 10%;
  box-sizing: border-box;
}
.contentCadrePlanning .cadrePlanning {
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 1px 0;
}
.contentCadrePlanning .cadrePlanning .headerCadrePlanning {
  width: 100%;
  height: 100px;
  background: white;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.headerCadrePlanning .cadreIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 5px;
}
.headerCadrePlanning .cadreIcon img,
.contentImgcadrePlanning img,
.iconCadreGrid img,
.cadreBannerSolution img,
.cadrePlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.headerCadrePlanning .textCadreIcon {
  margin: 0 10px 0 10px;
  font-size: 16px;
  color: #172b4d;
  font-weight: bold;
  letter-spacing: 0px;
}
.contentImgcadrePlanning {
  width: 100%;
  height: 200px;
  display: block;
  position: relative;
}

.buttonLanding {
  border: none;
  width: 120px;
  height: 50px;
  color: white;
  border-radius: 3px;
  margin: 20px auto;
  background: #ed7122;
}
.cadreBannerSolution {
  width: 100%;
  height: 450px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.cadrePlay {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
}
.overImgBanner.cadre {
  color: transparent;
  text-align: left;
  margin: 0;
}
.overImgBanner.cadre:hover {
  background-color: white;
  cursor: pointer;
  color: #172b4d;
  padding: 10px 15px;
  box-sizing: border-box;
  letter-spacing: 0px;
  font-size: 15px;
  line-height: 1.5;
}

.content-grid {
  width: 100%;
  height: auto;
  padding: 0 10%;
  margin: 0 auto;
}
.cadreGrid,
.cadreGridFirst {
  width: 300px;
  height: 400px;
  background-color: white;
  margin: 20px auto;
  border-radius: 10px;
  color: #172b4d;
  box-shadow: 0 0 4px 0 rgba(66, 25, 25, 0.25);
  padding: 10%;
  box-sizing: border-box;
}
.cadreGridFirst:hover,
.cadreGrid:hover {
  background-color: #172b4d;
  color: white;
  cursor: pointer;
}
.cadreGridColor {
  align-items: center;
  box-sizing: border-box;
}
.cadreGridFirst {
  margin-top: 50%;
}
.cadreGridHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.iconCadreGrid {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ed7122;
}
.cadreGridHeader h3 {
  margin: 0 5px;
}
.cadreGridContent {
  font-size: 16px;
  line-height: 1.5;
  margin: 5% 0;
}

.content-offer {
  width: 100%;
  padding: 1% 10%;
  background-color: #f7fafcfa;
  box-sizing: border-box;
}
.cadreOffe {
  background-color: #172b4d;
  margin: 10px 0;
  border-radius: 5px;
  color: white;
  padding: 5%;
}
.cadreOfferLeft {
  color: white;
}
.cadreOfferLeft h1 {
  color: white;
  font-size: 3rem;
  margin-bottom: 2%;
}
.cadreOfferLeft > div {
  display: flex;
  justify-content: flex-start;
  font-size: 2rem;
  align-items: center;
  margin: 4% 0;
}
.cadreOfferLeft > div > span {
  width: 45px;
  height: 45px;
  background: #ed7122;
  border-radius: 50%;
  margin-right: 10px;
}
.cadreOfferLeft > div > span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.cadreOfferRight {
  background-color: #344967;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: 10%;
  box-sizing: border-box;
  text-align: center;
}
.cadreOfferRight h1 {
  color: white;
  font-weight: 400;
  margin: 0;
}
.offerPrice {
  padding: 5% 5px;
  color: white;
  background-color: #233857;
  border-radius: 10px;
  margin-top: 5%;
}
.offerPrice span {
  margin-top: -7px;
}
.cadreOfferRight p {
  margin: 5% 10px;
}

.testimonial-cadre {
  box-shadow: 0 0 1px 0;
  border-radius: 5px;
  padding: 3%;
  margin: 5% 10px;
  box-sizing: border-box;
  position: relative;
  height: 350px;
}
.testimonial-cadre p {
  margin-bottom: 3%;
  line-height: 1.5;
}
.TestimonialCadreImg {
  width: 80px;
  height: 80px;
  margin: 10px;
  align-items: center;
}
.TestimonialCadreImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  border-radius: 50%;
}
.slick-dots li {
  border-radius: 50%;
}
.slick-dots li button {
  background-color: white !important;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(237, 113, 34, 0.3);
}
.slick-dots li button::before {
  color: #ed7122 !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  margin-top: 1px !important;
}
.positionQuote {
  width: 45px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: -7%;
  z-index: 10000;
}
.positionQuote img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}

.contact-content {
  background-color: #ed7122;
  color: white;
  margin: 5% 0;
  border-radius: 10px;
  padding: 5%;
  color: white;
  text-align: left;
}
.contact-content h2 {
  font-size: 3rem;
}
.contact-form input {
  width: 100%;
  height: 30px;
  border: none;
  padding: 0 5px;
  color: #172b4d;
  border-radius: 2px;
  box-sizing: border-box;
}
.contact-form textarea {
  width: 100%;
  height: 80px !important;
  border: none;
  box-sizing: border-box;
  padding: 10px 5px;
  border-radius: 2px;
}
.contact-form button {
  width: 150px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
}

.content-footer {
  box-sizing: border-box;
  background-color: #fff7ed;
  padding: 3% 0;
  overflow: hidden;
}
.footer-left {
  width: 100%;
  background-color: white;
  border-radius: 0 100px 100px 0;
  padding: 5% 10% 5% 30%;
  box-sizing: border-box;
  margin-top: 10%;
}
.footer-left p {
  line-height: 1.5;
  margin: 10px 0;
  font-weight: 400;
}
.footer-right {
  color: #172b4d;
}
.footer-right p {
  margin: 10px 0;
  line-height: 1.5;
}
.styleFaq {
  padding: 3% 10%;
  margin-top: 100px;
  box-sizing: border-box;
}
.styleFaq h1 {
  text-align: center;
  font-size: 3rem;
  color: #172b4d;
}
.styleFaq .questionText {
  width: 50%;
  text-align: center;
  color: #172b4d;
  margin: 0 auto;
}
.accordion-content {
  padding: 0 10%;
  color: #172b4d;
  font-size: 16px;
}
.accordion-content .accordion-summary {
  margin: 10px 0;
}
.accordion-content .accordion-summary p {
  font-size: 14px;
}

.content-login {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
}
.cadreImgLogin,
.cadreFormlogin {
  width: 100%;
  height: 100vh;
}
.cadreImgLogin img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.cadreImgLogin {
  position: relative;
}
.cadreFormlogin {
  padding: 15% 10% 0 10%;
  color: #172b4d;
  box-sizing: border-box;
}
.cadreFormlogin h1 {
  font-size: 3rem;
  text-align: center;
}
.cadreFormlogin h1 span {
  text-transform: uppercase;
  color: #ed7122;
}
.cadreFormlogin p {
  font-size: 14px;
  text-align: center;
}
.cadreFormlogin p span {
  color: #ed7122;
}
.cadreImgLogin .overTextLogin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000005a;
  padding: 10%;
  box-sizing: border-box;
}
.overTextLogin h2 {
  margin-top: 15%;
}
.overTextLogin p {
  font-size: 14px;
  color: white;
  padding: 15% 10% 0 10%;
}
.overTextLogin h3 {
  color: white;
  text-align: right;
  margin-top: 45%;
}
.overTextLogin h3 span,
.forgetStyle p span {
  color: #ed7122;
}
.formLogin {
  text-align: center;
  margin-top: 10%;
}
.styleListCabinet {
  margin: 5px auto;
  width: 70%;
}
.styleListCabinet > div {
  display: flex;
  margin: 10px 0;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(23, 43, 77, 0.3);
  padding: 10px 0;
}
.styleListCabinet > div > p {
  font-size: 16px;
}
.form-control {
  display: block;
  width: 70%;
  margin: 10px auto;
}
.form-input {
  font-size: 16px !important;
  outline: none !important;
  box-sizing: border-box;
}
.formLogin .buttonFormLogin {
  width: 70%;
  height: 50px;
  text-align: center;
  background-color: #ed7122;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.forgetStyle {
  display: flex;
  width: 70%;
  margin: 10px auto;
  color: #172b4d;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.formBar {
  width: 40%;
  height: 1px;
  background-color: #172b4d;
}
.content-formBar {
  width: 70%;
  margin: 10px auto;
  align-items: center;
}
button {
  cursor: pointer;
}
.loadeloging {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(23, 43, 77, 0.5);
  padding-top: 20%;
  align-items: center;
}
