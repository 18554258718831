.styleCadreBesoin{
    display: block;
    width: 100%;
    padding: 5px;
    margin: 5px 0px;
}
.styleCadreBesoin .contentCadreBesoins{
    width: 95%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    border-top: 5px solid rgb(237, 113, 34);
    border-radius: 5px;
}
.contentCadreBesoins h3{
    margin: 10px 7px;
    font-size: 16px;
}
.contentCadreBesoins p{
    border-top: 1px solid  rgba(0, 0, 0, 0.3);
    padding: 10px;
    text-align: left;
    margin-bottom: 5px;
}
.enteteListBesoins{
    display: flex;
    justify-content: space-between;
    margin: 10px 3px;
}
.enteteListBesoins h3{
    color: #ED7122;
    font-size: 20px;
    font-weight: normal;
}
.besoinBtnAffichageStyle{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.besoinBtnAffichageStyle p{
    margin: 5px;
    color: black;
}
.besoinBtnAffichageStyle button{
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    width: auto;
    padding: 5px;
}
.styleBtnFooterBesoin{
    display: flex;
    justify-content: left;
    padding: 5px;
}
.styleBtnFooterBesoin button{
    margin: 0 15px;
    padding: 5px 10px;
    border: 1px solid #ED7122 ;
    border-radius: 20px;
    color: black;
}
.styleFooterTestBesoin{
    display: flex;
    justify-content: space-around;
}
.styleFooterTestBesoin h3{
    font-size: 14px;
    width: 70%;
}
.styleFooterTestBesoin span{
    margin-top: 20px;
}
.activateBtn{
    background: #ED7122;
    color: white;
}
.documentStyle{
    color: #ED7122;
    font-size: 20px;
}
.cusorToggleText{
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
}
.cursorDelete{
    color: red;
}
.cadreTextReadMoreStyle{
    height: 135px;
}
.cadreTextReadMoreStyleNone{
    height: auto;
}
.StyleAccordionBesoin{
    background-color: #F7FAFC;
    border-left: 5px solid #ED7122;
    display: flex;
    justify-content: space-between;
}
.accordionTextStyle{
    height: auto;
    padding: 10px;
}
.textH1OfQuestion{
    font-size: 2rem;
    color: #8898AA;
    text-align: center;
    font-weight: 400;
    margin: 5px 0;
}
.cadreListQuestionnaire{
    width: 10px;
    height: 10px;
    background: #ED7122;
    border-radius: 3px;
}
.textInfo{
    color: #8898AA;
    font-size: 1.6rem;
    font-weight: 400;
}
.groupCheckbox{
    display: flex;
    justify-content: center;
}
.cadreObservation{
    height: 70px;
    border-radius: 3px;
}
.cadreBesoinGrille{
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    color: #8898AA;
    font-size: 1.5rem;
}
.cadreBesoinGrille .borderCadreBesoin{
    width: 100%;
    height: 3px;
    background: #ED7122;
    display: block;
    margin-bottom: 5px;
    background-color: #FEB969;
}
.barreBesoin{
    width: 100%;
    height: 1px;
    background: #8898AA;
    margin: 4px 0;
}
.groupBtnBesoin{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
}
.groupBtnPlanning{
    width: 100%;
    height: auto;
}
.groupBtnBesoin button, .colorInputNeed{
    width: auto;
    height: auto;
    padding: 5px 10px;
    background: rgba(237, 113, 34, 0.1);
    color: #ED7122;
    margin-right: 10px;
    outline: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}
.groupBtnPlanning button{
    width: auto;
    height: auto;
    padding: 5px 10px;
    margin-right: 10px;
    outline: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}
.groupBtnPlanning button{
    background: rgba(237, 113, 34, 0.1);
    color: #ED7122;
}
.groupBtnPlanning button.activedColor{
    background: #ED7122;
    color: white;
}
.hearderListeBesoin{
    height: 500px;
    overflow: scroll;
}
.autoCompleteStyleNeed{
    font-size: 16px !important;
}