.CadreStyle{
    padding: 0;
    width: 100%;
    height: auto;
}
.__bodyContent{
    padding: 15px 0 5px 10px;
    width: 100%;
    height: auto;
    margin-bottom: 7px;
}
.__titleHeader{
    font-size: 1.2rem;
    color: #8898AA;
    margin: 5px 0;
    width: 100%;
   padding: 0 0 5px 0;
}
.__styleTitleCollaborateur{
    font-size: 1.1rem;
    text-transform: uppercase;
    color: #8898AA;
    width: 100%;
}
.__styleListCollaborateur{
    font-size: 1.1rem;
    color: #43425D;
}
._stylelistNameCollaborateur{
    color: #172B4D;
}
.__montant{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2rem;
}
.raisonSocialStyle{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
}
.footerCard_style{
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 1rem;
}
.footerCard_style button{
    width: auto;
    height: auto;
    background-color: rgba(237, 113, 34, 0.1);
    color: #ED7122;
    font-size: 13px;
    text-align: center;
    outline: none;
    border-radius: 3px;
    border: none;
    padding: 2px 15px;
    font-weight: 400;
    cursor: pointer;
}
.iconeCollaborateur{
    background: #fff2d8;
}
.searchCollaborateur{
    margin-top: -20px;
}
.cadreIconeWidget{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin: 15px auto 0 auto;
    text-align: center;
}
.IconeWidget{
    width: 60%;
    height: 60%;
    margin: 10px auto;
}
.IconeWidgetProfile{
    width: 70%;
    height: 70%;
    margin: 7px auto;
}
.MuiPaper-elevation1{
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.07);
}
.OverDetailProfile{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.07);
    z-index: 10000;
}
.OverDetailProfile.activeProfile{
    display: block;
}
.DisplayProfile{
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: flex-end;
}
.DisplayProfile .contentProfile{
    width: 300px;
    height: 100%;
    background-color: white;
    padding: 10px;
    box-sizing: border-box;
}
.contentProfile .colorIcones{
    color: red;
}
.cadreProfileImg{
    margin: 5px auto;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}
.cadreProfileImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
}