._styleModaleHeader{
    padding: 0;
    margin: 0;
    width: 350px;
    height: 350px;
}
._styleModaleHeaderCreate{
    padding: 0;
    margin: 0;
    width: 350px;
    height: 300px;
    background: white;
    box-shadow: 0 10px 0 0 rgba(0, 0, 0, 0.7);
}
._styleModaleHeaderCreate h3{
    text-align: center;
    color: #ED7122;
    padding: 10px 0;
    text-align: center;
    font: normal normal 300 31px/40px Roboto;
    letter-spacing: 0px;
    color: #ED7122;
}
._styleModaleHeader img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    margin: 0;
}
._styleTextModale{
    width: 100%;
    height: auto;
    padding: 15px 0;
    text-align: center;
    font-size: 1.6rem;
    color: black;
}
.__styledialogContent{
    background: #ED7122;
    padding: 0;
    position: relative;
    overflow: hidden;
}
.__styledialogContentCreate{
    background-color: rgba(0, 0, 0, 0.01);;
    padding: 0;
    width: auto;
    height: auto;
    margin: 0;
    position: relative;
    overflow: hidden;
}
._styleTextModalContent{
    position: absolute;
    background: white;
    width: 310px;
    height: 100px;
    padding: 25px 20px;
    bottom: 0;
    left: 0;
    text-align: center;
    overflow: hidden;
}
.closeStyle{
    position: absolute;
    background: #ED7122;
    width: 50px;
    height: 80px;
    color: white;
    padding: 10px 0 0 10%;
    top: 0;
    right: 0;
    cursor: pointer;
}
.closeStyleCreate{
    background: #ED7122;
    width: 300px;
    height: 40px;
    color: white;
    margin: auto;
    display: flex;
    padding: 5px 7px;
    justify-content: flex-end;
    border-radius: 25px 25px 0px 0px ;
    cursor: pointer;
    box-sizing: border-box;
}
._styleTextModalContent h2{
    font-weight: normal;
    color: #ED7122;
    font-size: 2.7rem;
}
._styleTextModalContent h3{
    font-weight: initial;
    color: #ED7122;
    height: auto;
    padding: 5px 10%;
    font-size: 2rem;
}
.__styleGroupeModaleBtn{
    display: flex;
    justify-content: space-evenly;
    margin: 10px auto;
    color: white;
}
._styleTextModalContent span{
    font-weight: initial;
    height: auto;
    width: 100%;
    font-size: 1.6rem;
}
